// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfcustomform24 from "../../blocks/cfcustomform24/src/Cfcustomform24";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import BeeFreeIntegration from "../../blocks/beefreeintegration/src/BeeFreeIntegration";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Cfchangerequests22 from "../../blocks/cfchangerequests22/src/Cfchangerequests22";
import Cfchangerequests12 from "../../blocks/cfchangerequests12/src/Cfchangerequests12";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";



const routeMap = {
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfcustomform24:{
 component:Cfcustomform24,
path:"/Cfcustomform24"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
BeeFreeIntegration:{
 component:BeeFreeIntegration,
path:"/BeeFreeIntegration"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Cfchangerequests22:{
 component:Cfchangerequests22,
path:"/Cfchangerequests22"},
Cfchangerequests12:{
 component:Cfchangerequests12,
path:"/Cfchangerequests12"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;